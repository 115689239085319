import React from "react";
import { graphql } from "gatsby";
//import { Link } from "gatsby";

//import { FaTwitter, FaFacebook } from "react-icons/fa";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import RelatedPost from "../components/RelatedPost";
//import { SOCIAL } from "../constants";

/**
 * Blog post page
 *
 * Loads the specific post and presents the reader with content
 * in an easily digestible format. Should also have the header
 * and a place to subscribe to my newsletter.
 */

class Post extends React.Component {
  constructor(props) {
    super(props);
    this.setState({ key: Math.random() });
  }

  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const post = data.ghostPost;
    /**const twitterShare = `http://twitter.com/share?text=${encodeURIComponent(
      post.title
    )}&url=https://www.conordewey.com/blog/${post.slug}/&via=conordewey3`;
    const facebookShare = `https://www.facebook.com/sharer/sharer.php?u=https://www.conordewey.com/blog/${post.slug}`; **/
    const posts = data.allGhostPost.edges;
    const shuffled = posts.sort(() => 0.5 - Math.random());
    let selected = shuffled.slice(0, 3);

    return (
      <Layout title={siteTitle}>
        <>
          <SEO
            title={post.title.replace(
              /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
              ""
            )}
            description={post.excerpt || data.site.siteMetadata.description}
            image={post.feature_image}
            pathname={post.slug}
            article={post.html}
          />

          <article className="post">
            <header>
              <h1 className="postTitle">{post.title}</h1>
              <div
                style={{
                  display: "flex"
                }}
              >
                <p className="postSubheader">
                  {post.published_at} • {post.reading_time + 1} min •{" "}
                  {post.primary_tag.name == null ? "" : post.primary_tag.name}
                </p>
              </div>
            </header>
            <section
              className="postContent"
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
          </article>
          <div
            style={{
              marginTop: "2rem",
              paddingBottom: "-0.5rem",
              marginBottom: "-2.5rem",
              fontFamily: "'Karla', 'Helvetica Neue', sans-serif"
            }}
          >
            {selected.map(({ node }) => {
              const title = node.title || node.slug;
              return <RelatedPost node={node} title={title} />;
            })}
          </div>
        </>
      </Layout>
    );
  }
}

export default Post;

export const postQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    ghostPost(slug: { eq: $slug }) {
      title
      slug
      html
      excerpt
      feature_image
      published_at(formatString: "MMM DD, YYYY")
      reading_time
      primary_tag {
        name
      }
    }
    allGhostPost(sort: { order: DESC, fields: [published_at] }) {
      edges {
        node {
          title
          slug
          reading_time
          published_at(formatString: "MMM DD, YYYY")
          excerpt
          primary_tag {
            name
          }
        }
      }
    }
  }
`;

/**
 * 
 *  <div>
                  <a
                    className="icon"
                    style={{
                      display: "inline-block",
                      verticalAlign: "baseline",
                      marginRight: "13px",
                      marginTop: "22px",
                      marginLeft: "19px"
                    }}
                    href={twitterShare}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTwitter size={24.5} title={"Share on Twitter"} />
                  </a>
                  <a
                    className="icon"
                    style={{
                      display: "inline-block",
                      verticalAlign: "baseline"
                    }}
                    href={facebookShare}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebook size={24.5} title={"Share on Facebook"} />
                  </a>
                </div>
 */
