import React from "react";
import { Link } from "gatsby";

/**
 * Mini post preview component
 *
 * Shows the title of a blog post and a date published. For the archive page to
 * show everything I've written in a more condensed form.
 */
const RelatedPost = ({ node, title }) => {
  return (
    <article className="relatedPosts" key={node.slug}>
      <header>
        <h2 className="relatedPostHeader">
          <Link
            style={{
              boxShadow: "none"
            }}
            to={`/blog/${node.slug}/`}
          >
            {title}
          </Link>
        </h2>
        <p className="relatedPostSubheader">
          {node.published_at} • {node.reading_time + 1} min
        </p>
      </header>
    </article>
  );
};

export default RelatedPost;
